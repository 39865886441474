import React, { FC, ReactNode } from 'react';

interface Properties {
  children: ReactNode;
}

const Body: FC<Properties> = ({ children }) => {
  return <>{children}</>;
};

export default Body;
