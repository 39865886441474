import React, { FC, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { RecoveryStage } from '../types/recovery-stage';
import { onDevice } from '../constants/theme';

//  Assets
import scan from '../assets/images/scan_qr.png';
import { H4 } from '@formelio/react-components';
import { AuthContext } from '..';
import { encodeBody } from '../lib/KeycloakRequests';

const IrmaCore = require('@privacybydesign/yivi-core');
const Web = require('@privacybydesign/yivi-web');
const Client = require('@privacybydesign/yivi-client');
require('@privacybydesign/yivi-css');
const qrSize = '180px';

const Box = styled.div`
  width: 100%;
  padding: ${(p) => p.theme.size.space.m2};
  border: 1px solid ${(p) => p.theme.palette.grayDark};
  border-radius: 16px;
  position: relative;

  ${onDevice.mobile} {
    display: flex;
    justify-content: center;
    border: none;
  }
`;

const ScanContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${(p) => p.theme.size.space.m2};

  // Delete default size of IRMA element
  .irma-web-form {
    max-width: unset;
    min-width: unset;
    min-height: unset;
    max-height: unset;
    background-color: white;
    border-radius: 0;
  }

  > img {
    width: 150px;
    height: 135px;
  }

  ${onDevice.mobile} {
    display: flex;
    justify-content: center;

    img {
      display: none;
    }
  }
`;
const QrContainer = styled.section`
  height: ${qrSize};
  width: ${qrSize};

  // Overrides irma-css configurations
  min-height: unset;
  max-width: ${qrSize};
  overflow: hidden;

  // built in IRMA styling
  .yivi-web-header {
    display: none;
  }

  // makes sure IRMA elements take full space
  div {
    margin: 0 !important;
  }

  /* This p is the text button */
  /* Examples: 'load QR' or 'try again' */
  p {
    margin: ${(p) => p.theme.size.space.m2} 0 0 0 !important;
  }

  // QR code
  .yivi-web-qr-canvas {
    width: ${qrSize} !important;
    height: ${qrSize} !important;
  }

  ${onDevice.mobile} {
    /* This p is the text button */
    /* Examples: 'load QR' or 'try again' */
    p {
      position: absolute;
      bottom: ${(p) => p.theme.size.space.m2};
      left: 0;
      right: 0;
      background-color: transparent;
      margin: 0 !important;
    }
  }
`;

const Content = styled.div`
  letter-spacing: ${(p) => p.theme.size.letterSpacing.xs};
  opacity: 0.8;

  h4 {
    font-size: ${(p) => p.theme.font.size.s};
    opacity: 1;
    letter-spacing: ${(p) => p.theme.size.letterSpacing.s};
  }

  li {
    font-size: ${(p) => p.theme.font.size.s};
    line-height: ${(p) => p.theme.size.lineHeight.m};
    margin-bottom: ${(p) => p.theme.size.space.s};
    opacity: 0.8;

    span {
      margin-right: 12px;
    }
  }

  li:last-child {
    margin: 0;
  }

  ${onDevice.mobile} {
    display: none;
  }
`;

interface IRecoveryIrmaSession {
  setRecoveryStage: (status: RecoveryStage) => void;
  irmaSession: any;
  actionUrl: string;
  updateActionUrl: (newActionUrl: string) => void;
}

const RecoveryIrmaSession: FC<IRecoveryIrmaSession> = ({
  setRecoveryStage,
  irmaSession,
  actionUrl,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateActionUrl,
}) => {
  const { irmaServerBase } = useContext(AuthContext);

  useEffect(() => {
    let contentType: string;
    try {
      JSON.parse(irmaSession);
      contentType = 'application/json';
    } catch (_e) {
      contentType = 'text/plain';
    }

    const irma = new IrmaCore({
      debugging: true, // Enable to get helpful output in the browser console
      element: '#irma-web-form', // Which DOM element to render to
      language: 'nl',
      no_auth: false,
      session: {
        url: irmaServerBase.replace(/\/$/, ''),

        start: {
          method: 'POST',
          headers: { 'Content-Type': contentType },
          body: irmaSession,
        },

        result: {
          url: (o: { url: string }, s: { sessionToken: string }): string => `${o.url}/session/${s.sessionToken}/result-jwt`,
          parseResponse: (r: string) => r,
        },
      },
    });

    irma.use(Web);
    irma.use(Client);

    irma
      .start()
      .then(async (response: Response) => {
        console.log('Successful disclosure!');

        const requestBody = encodeBody({
          is_recovery: true,
          irma_response: await response.text(),
          recovery_action: 'update_irma_attr',
        });

        const doUpdateResponse = await fetch(actionUrl, {
          method: 'POST',
          body: requestBody,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        });

        if (doUpdateResponse.ok) {
          setRecoveryStage(RecoveryStage.DONE);
        } else {
          console.error(await response.json());
        }
      })
      .catch((error: any) => {
        console.error("Couldn't do what you asked 😢", error);
      });

    return function cleanup() {
      if (irma != null) {
        irma.abort();
      }
    };
  }, []);

  return (
    <Box>
      <ScanContainer>
        <QrContainer id="irma-web-form"></QrContainer>
        <img src={scan} />
      </ScanContainer>
      <Content>
        <H4 mb={16}>Stappen om attribuut opnieuw op te halen:</H4>
        <ol>
          <li>
            <span>1.</span>Open de Yivi app op je smartphone
          </li>
          <li>
            <span>2.</span>Druk op ‘’Scan QR Code’’
          </li>
          <li>
            <span>3.</span>Richt je smartphone op de QR code in het scherm
          </li>
          <li>
            <span>4.</span>Klik op 'Gegevens delen' in de Yivi app
          </li>
        </ol>
      </Content>
    </Box>
  );
};

export default RecoveryIrmaSession;
