import { Button } from '@formelio/react-components';
import React, { FC } from 'react';
import styled from 'styled-components';

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IRecoveryDone {}

const RecoveryDone: FC<IRecoveryDone> = () => {
  // We could stay in the same page and get a new actionURL from Keycloak,
  // but simply reloading the page is the easiest way to ensure that
  // we get a new, fresh session

  return (
    <ButtonWrapper>
      <Button type="button" onClick={() => window.location.reload()}>
        Naar inloggen
      </Button>
    </ButtonWrapper>
  );
};

export default RecoveryDone;
