import React, { FC, useEffect, useRef } from 'react';

interface ISelfSubmittingForm {
  actionUrl: string;
  values: { [name: string]: string | undefined };
}

const SelfSubmittingForm: FC<ISelfSubmittingForm> = ({ actionUrl, values }) => {
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    formRef.current && formRef.current.submit();
  }, []);

  return (
    <form ref={formRef} action={actionUrl} method="POST">
      {Object.entries(values).map(([name, value]) => (
        <input type="hidden" key={name} name={name} value={value} />
      ))}
    </form>
  );
};

export default SelfSubmittingForm;
