import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { Paper } from '../layout';
import { Login } from '../components';
import { onDevice } from '../constants/theme';
import { AuthContext } from '..';

const Main = styled.div`
  width: 100%;
  height: 100%;
`;

const Footer = styled.div`
  height: 40px;
  width: 100%;
  background-color: #d8d8d8;

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  font-size: ${(p) => p.theme.font.size.xs};

  div {
    text-align: center;
    width: 150px;
  }

  a {
    color: ${(p) => p.theme.palette.black};
    opacity: 0.7;
  }

  ${onDevice.mobile} {
    height: unset;
    flex-direction: column;
    justify-content: center;
    padding: 12px 0;

    div {
      margin: 0;
    }

    a {
      margin-bottom: 12px;
    }
  }
`;

interface IParams {
  toRecoveryPage: () => void
}

const LoginPage: FC<IParams> = ({toRecoveryPage}) => {
  const { realm, usernamePasswordLoginUrl } = useContext(AuthContext);

  const now = new Date();
  const year = now.getFullYear();

  return (
    <>
      <Main>
        <Paper width="420px" title="Login">
          <Login toRecoveryPage={toRecoveryPage}/>
        </Paper>
      </Main>
      {realm === 'hinq' && (
        <Footer>
          <div id="footer-left"></div>
          <div id="footer-center">
          Copyright &#xa9; HINQ {year}
          </div>
          <div id="footer-right">
          {usernamePasswordLoginUrl !== '' && (
            <a href={usernamePasswordLoginUrl}>Dimence & Altrecht Lite Log In</a>
          )}
          </div>
        </Footer>
      )}
    </>
  );
};

export default LoginPage;
