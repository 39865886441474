import { Button, Input, Spinner, Label, Error } from '@formelio/react-components';
import React, { FC, Fragment, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { encodeBody } from '../lib/KeycloakRequests';
import { RecoveryStage } from '../types/recovery-stage';

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 4px;
  margin-top: 64px;
`;

const InputWrapper = styled.div`
  min-height: 80px;
`;

const VerificationCodeInputField = styled(Input)`
  margin-bottom: 16px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
`;

interface IRecoveryVerificationCode {
  setRecoveryStage: (status: RecoveryStage) => void;
  setIrmaSession: (session: any) => void;
  actionUrl: string;
  updateActionUrl: (newActionUrl: string) => void;
}

const RecoveryVerificationCode: FC<IRecoveryVerificationCode> = ({
  setRecoveryStage,
  setIrmaSession,
  actionUrl,
  updateActionUrl,
}) => {
  const { register, handleSubmit } = useForm();
  const formRef = useRef<HTMLFormElement>(null);

  const [verificationCode, setVerificationCode] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [inputErrorText, setInputErrorText] = useState<string | undefined>(
    undefined
  );

  const onFormSubmit = async (data: any) => {
    setLoading(true);
    setInputErrorText(undefined);

    const requestBody = encodeBody(data);

    const response = await fetch(actionUrl, {
      method: 'POST',
      body: requestBody,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    if (response.ok) {
      const returned = await response.json();

      updateActionUrl(returned['new_action_url']);
      setIrmaSession(returned['irma_session_request']);
      setRecoveryStage(RecoveryStage.ISSUANCE);
    } else {
      setLoading(false);

      try {
        const returned = await response.json();

        console.error(returned);

        updateActionUrl(returned['new_action_url']);

        switch (returned['error_code']) {
          case 'incorrect_code':
            setInputErrorText('De code is onjuist');
            break;
          default:
            setInputErrorText(
              'Er is een onbekende fout opgetreden. Probeer het nog eens'
            );
            break;
        }
      } catch (err) {
        console.error(response);
        console.error(err);

        setInputErrorText(
          'Er is een onbekende fout opgetreden. Ververs de pagina en probeer het nog eens'
        );
      }
    }
  };

  const updateVerificationCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVerificationCode(e.target.value);
  };

  return (
    <form ref={formRef} noValidate onSubmit={handleSubmit(onFormSubmit)}>
      <input type="hidden" name="is_recovery" value="True" ref={register()} />
      <input
        type="hidden"
        name="recovery_action"
        value="verify_code"
        ref={register()}
      />

      {loading ? (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      ) : (
        <Fragment>
          <InputWrapper>
            <Label htmlFor="verification_code">Toegangscode</Label>
            <VerificationCodeInputField
              id="verification_code"
              name="verification_code"
              value={verificationCode}
              onChange={updateVerificationCode}
              forwardRef={register()}
            />
            {inputErrorText && <Error>{inputErrorText}</Error>}
          </InputWrapper>

          <ButtonWrapper>
            <Button type="submit">Controleren</Button>
          </ButtonWrapper>
        </Fragment>
      )}
    </form>
  );
};

export default RecoveryVerificationCode;
