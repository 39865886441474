import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import IAuthContext from './types/auth-context';
import { IntercomProvider } from 'react-use-intercom';
// require('dotenv').config();

const INTERCOM_APP_ID = 'pk1hldvp';

const rootElem = document.getElementById('root');

export const AuthContext = React.createContext<IAuthContext>(
  rootElem?.dataset as any
);

ReactDOM.render(
  <React.StrictMode>
    <AuthContext.Provider value={rootElem?.dataset as any}>
      <IntercomProvider
        appId={INTERCOM_APP_ID}
        apiBase='https://api-iam.eu.intercom.io'
        autoBoot
      >
        <App />
      </IntercomProvider>
    </AuthContext.Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
