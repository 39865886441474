import React, { FC, useContext, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { LoginPage, RecoveryPage } from './pages';
import Theme from './constants/theme';
import { AuthContext } from '.';

const App: FC = () => {
  const { recaptchaSiteKey } = useContext(AuthContext);
  const [onRecoveryPage, setOnRecoveryPage] = useState<boolean>(false);

  const toRecoveryPage = () => setOnRecoveryPage(true);
  const toLoginPage = () => setOnRecoveryPage(false);

  /*
   * refresh or recovery attribute, so sent them to the correct page
   */
  const queryParams = new URLSearchParams(window.location.search);
  const directRecovery = queryParams.get('recovery');

  return (
    <Theme>
      <GoogleReCaptchaProvider
        reCaptchaKey={recaptchaSiteKey}
        scriptProps={{
          async: true,
          defer: true,
        }}
      >
        <Router>
          <Switch>
            <Route path="/">
              {onRecoveryPage || directRecovery ? (
                <RecoveryPage toLoginPage={toLoginPage} />
              ) : (
                <LoginPage toRecoveryPage={toRecoveryPage} />
              )}
            </Route>
          </Switch>
        </Router>
      </GoogleReCaptchaProvider>
    </Theme>
  );
};

export default App;
