import React, { FC, ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';
import { rgba } from 'polished';
import breakpoints from './breakpoints';
import { css } from 'styled-components';

export const font = {
  family: {
    primary: 'Open Sans, sans-serif',
    secondary: 'OpenSansBold, sans-serif',
  },
  weight: {
    extraBold: 900,
    bold: 700,
    semiBold: 600,
    regular: 400,
    thin: 300,
    minimal: 100,
  },
  size: {
    xl: '24px',
    l: '20px',
    m: '16px',
    s: '14px',
    xs: '12px',
  },
};

export const palette = {
  /* Theme colors */
  primary: '#5c13ff',
  primaryLight: '#8d5aff',
  primaryLighter: '#bea1ff',
  primaryDark: '#400db2',
  primaryDarker: '#250866',

  secondary: '#c51ae4',
  secondaryLight: '#d65fec',
  secondaryLighter: '#e8a3f4',
  secondaryDark: '#8a12a0',
  secondaryDarker: '#4f0a5b',

  /* Standard colors */
  black: '#333333',
  white: '#ffffff',
  gray: '#e8e8e8',
  grayLight: '#f2f2f2',
  grayDark: '#dcdcdc',
  grayDarker: '#c1c1c1',
  fadedWhite: rgba('#ffffff', 0.3),

  /* Feedback colors  */
  error: '#e22f01',
  errorLight: '#FCEAE5',
  success: '#04b31c',
  successLight: '#cdf0d2',
  warning: '#f9a825',
  warningLight: '#feeed3',
  highlight: '#efe7ff',
};

export const color = {
  interactions: rgba(palette.primary, 0.9),
  hover: rgba(palette.primary, 0.2),
  shadow: '#0000003d',
  text: {
    primary: palette.black,
    light: rgba(palette.black, 0.9),
    lighter: rgba(palette.black, 0.6),
  },

  header: palette.white,
  background: palette.grayLight,
};

export const size = {
  lineHeight: {
    double: 2,
    xl: 1.75,
    l: 1.66,
    m: 1.5,
    s: 1.33,
    xs: 1.25,
    default: 1,
  },
  space: {
    xxxl: '160px',
    xxl: '88px',
    xl: '56px',
    l: '32px',
    m2: '24px',
    m1: '20px',
    m: '16px',
    s: '8px',
    xs: '4px',
  },
  letterSpacing: {
    xl: '1.25px',
    l: '0.5px',
    m: '0.25px',
    s: '0.1px',
    xs: '0.01px',
    none: 0,
  },
  drawer: {
    desktop: '64px',
    mobile: '50px',
  },
  header: {
    desktop: '72px',
    mobile: '50px',
  },
};

export const layout = {
  center: 'display: flex; align-items: center; justify-content: center;',
};

export const disabledTextSelection = css`
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version*/
`;

export const onDevice = {
  desktop: `@media only screen and (min-width: ${breakpoints.desktopS}px)`,
  mobile: `@media only screen and (max-width: ${breakpoints.mobileM}px)`,
  tablet: `@media only screen and (min-width: ${breakpoints.mobileL}px)`,
  // mobile: `@media only screen and (max-width: ${breakpoints.desktopS}px)`,
};

export const onDeviceMedia = {
  desktop: `screen and (min-width: ${breakpoints.desktopS}px)`,
  mobile: `screen and (max-width: ${breakpoints.mobileL}px)`,
};

const theme = {
  font,
  palette,
  color,
  size,
  layout,
  disabledTextSelection,
  onDevice,
  onDeviceMedia,
};

interface Theme {
  children: ReactNode;
}

const Theme: FC = ({ children }) => {
  // @ts-expect-error Not sure how to fix this
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Theme;
