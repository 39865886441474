import React, { FC } from 'react';
import { palette } from '../constants/theme';
import { Helmet } from 'react-helmet';
import { AuthContext } from '..';

const Meta: FC = () => {
  const { resourcesPath, realm } = React.useContext(AuthContext);

  return (
    <Helmet>
      <link rel="manifest" href={`${resourcesPath}/manifest.json`} />
      <link
        rel="shortcut icon"
        type="image/x-icon"
        href={`${resourcesPath}/manifest/${realm}-favicon.ico`}
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`${resourcesPath}/manifest/${realm}-apple-touch.png`}
      />
      <link
        rel="icon"
        type="image/x-icon"
        sizes="32x32"
        href={`${resourcesPath}/manifest/${realm}-32x32.png`}
      />
      <link
        rel="icon"
        type="image/x-icon"
        sizes="16x16"
        href={`${resourcesPath}/favicon/${realm}-icon-16x16.png`}
      />
      <link
        rel="mask-icon"
        href={`${resourcesPath}/favicon/safari-pinned-tab.svg`}
        color={palette.white}
      />
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Open+Sans&display=swap"
        rel="stylesheet"
      />
      <meta name="msapplication-TileColor" content={palette.white} />
      <meta
        name="msapplication-config"
        content={`${resourcesPath}/favicon/browserconfig.xml`}
      />
      <meta name="theme-color" content={palette.primary} />
      <link rel="alternate" type="application/rss+xml" href="/feed.xml" />
      <meta name="description" content={`${realm}`} />
      <meta
        property="og:image"
        content={`${resourcesPath}/manifest/${realm}-icon-512x512.png`}
      />
    </Helmet>
  );
};

export default Meta;
