import React, { FC, Fragment, useRef, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import { Button, Input, Spinner, Label, Error } from '@formelio/react-components';
import { RecoveryStage } from '../types/recovery-stage';
import { encodeBody } from '../lib/KeycloakRequests';

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 4px;
  margin-top: 64px;
`;

const InputWrapper = styled.div`
  min-height: 80px;
`;

const EmailInputField = styled(Input)`
  margin-bottom: 16px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
`;

interface IRecoveryEmail {
  setRecoveryStage: (status: RecoveryStage) => void;
  actionUrl: string;
  updateActionUrl: (newActionUrl: string) => void;
}

const RecoveryEmail: FC<IRecoveryEmail> = ({
  setRecoveryStage,
  actionUrl,
  updateActionUrl,
}) => {
  const { register, handleSubmit } = useForm();
  const formRef = useRef<HTMLFormElement>(null);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const [loadingRecaptcha, setLoadingRecaptcha] = useState<boolean>(false);
  const [emailValue, setEmailValue] = useState<string>('');
  const [inputErrorText, setInputErrorText] = useState<string | undefined>(
    undefined
  );

  const onFormSubmit = async (data: any) => {
    setInputErrorText(undefined);

    if (executeRecaptcha) {
      setLoadingRecaptcha(true);

      const recaptchaResponse = executeRecaptcha('attr_recovery_email_check');
      data['g-recaptcha-response'] = await recaptchaResponse;
    } else {
      console.error('Could not execute recaptcha');
    }

    const requestBody = encodeBody(data);

    const response = await fetch(actionUrl, {
      method: 'POST',
      body: requestBody,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    if (response.ok) {
      const returned = await response.json();

      updateActionUrl(returned['new_action_url']);
      setRecoveryStage(RecoveryStage.VERIFICATION_CODE);
    } else {
      setLoadingRecaptcha(false);

      try {
        const returned = await response.json();

        console.error(returned);
        updateActionUrl(returned['new_action_url']);

        switch (returned['error_code']) {
          case 'missing_email':
            setInputErrorText('Vul een e-mailadres in');
            break;
          case 'missing_user':
            setInputErrorText('Dit e-mailadres is onbekend');
            break;
          case 'no_irma_attr':
            setInputErrorText('Dit e-mailadres heeft geen IRMA attribuut');
            break;
          default:
            setInputErrorText(
              'Er is een onbekende fout opgetreden. Probeer het nog eens'
            );
            break;
        }
      } catch (err) {
        console.error(response);
        console.error(err);

        setInputErrorText(
          'Er is een onbekende fout opgetreden. Ververs de pagina en probeer het nog eens'
        );
      }
    }
  };

  const updateEmailValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailValue(e.target.value);
  };

  return (
    <form ref={formRef} noValidate onSubmit={handleSubmit(onFormSubmit)}>
      <input type="hidden" name="is_recovery" value="True" ref={register()} />
      <input
        type="hidden"
        name="recovery_action"
        value="send_email"
        ref={register()}
      />

      {loadingRecaptcha ? (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      ) : (
        <Fragment>
          <InputWrapper>
            <Label htmlFor="email">E-mailadres</Label>
            <EmailInputField
              id="email"
              name="email"
              value={emailValue}
              onChange={updateEmailValue}
              forwardRef={register()}
            />
            {inputErrorText && <Error>{inputErrorText}</Error>}
          </InputWrapper>

          <ButtonWrapper>
            <Button type="submit">Opvragen</Button>
          </ButtonWrapper>
        </Fragment>
      )}
    </form>
  );
};

export default RecoveryEmail;
