const breakpoints = {
  desktopL: 1280,
  desktopS: 1024,
  mobileL: 768,
  mobileM: 600,
  mobileS: 520,
  mobileXS: 460,
};

export default breakpoints;
