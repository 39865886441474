import React, { FC, ReactNode, useContext } from 'react';
import styled from 'styled-components/macro'; //babel macro allows classnames when debugging
import Blank from './Blank';
import { AuthContext } from '..';
import { onDevice } from '../constants/theme';

interface IHeader {
  realm: string;
}

const Header = styled.div<IHeader>`
  position: absolute;
  width: 100%;
  padding-top: 40px;
  padding-left: 40px;

  background-color: ${(p) => p.theme.palette.white};

  display: flex;

  img {
    height: ${(p) => (p.realm === 'ivido' ? '30px' : '80px')};
  }

  ${onDevice.mobile} {
    display: flex;
    justify-content: flex-end;
    padding: 24px;
    img {
      height: ${(p) => (p.realm === 'ivido' ? '20px' : '50px')};
    }
  }
`;

interface IMain {
  backgroundColor?: string;
}

const Main = styled.div<IMain>`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${(p) =>
    p.backgroundColor ? p.backgroundColor : p.theme.palette.white};
`;
interface IStyledPaper {
  paperBackgroundColor?: string;
  width?: string;
}

const StyledPaper = styled.div<IStyledPaper>`
  width: ${(p) =>
    p.width ? p.width : "420px"};
  background-color: ${(p) =>
    p.paperBackgroundColor ? p.paperBackgroundColor : p.theme.palette.white};
`;

interface Properties {
  children: ReactNode;
  title: string;
  backgroundColor?: string;
  paperBackgroundColor?: string;
  width: string;
}

const Paper: FC<Properties> = ({
  children,
  title,
  backgroundColor,
  paperBackgroundColor,
  width
}) => {
  const { realm, resourcesPath } = useContext(AuthContext);

  const ivido = `${resourcesPath}/logos/ivido.png`;
  const hinq = `${resourcesPath}/logos/hinq.svg`;

  return (
    <Blank title={title}>
      <Header realm={realm}>
        <img src={realm === 'ivido' ? ivido : hinq} />
      </Header>
      <Main backgroundColor={backgroundColor}>
        <StyledPaper paperBackgroundColor={paperBackgroundColor} width={width}>
          {children}
        </StyledPaper>
      </Main>
    </Blank>
  );
};

export default Paper;
