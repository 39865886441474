import React, { FC, ReactNode, ReactElement } from 'react';
import styled from 'styled-components/macro'; //babel macro allows classnames when debugging
import Page from './Page';
import { palette } from '../constants/theme';

const StyledBlank = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  background-color: ${palette.white};
`;

interface Properties {
  children: ReactNode;
  title: string | ReactElement;
}

const Blank: FC<Properties> = ({ children, title }) => {
  return (
    <StyledBlank>
      <Page title={title}>{children}</Page>
    </StyledBlank>
  );
};

export default Blank;
