import React, { FC, ReactNode, ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { AuthContext } from '..';
import Body from './Body';
import Meta from './Meta';

interface Properties {
  title: string | ReactElement;
  children: ReactNode;
}

const Page: FC<Properties> = ({ title, children }) => {
  const { realm } = React.useContext(AuthContext);

  const app = realm == 'ivido' ? 'Ivido' : 'HINQ';

  return (
    <>
      <Helmet>
        <title>
          {title} - {app}
        </title>
      </Helmet>
      <Meta />
      <Body>{children}</Body>
    </>
  );
};

export default Page;
