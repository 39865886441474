export const encodeBody = (data: any): string => {
    const formParams = [];

    for(const param in data) {
      const key = encodeURIComponent(param);
      const val = encodeURIComponent(data[param]);

      formParams.push(key + '=' + val);
    }

    return formParams.join('&');
}
