export enum IrmaStatus {
  READY,
  IN_PROGRESS,
  FINISHED,
  FAILED,
}

export enum IrmaError {
  ERROR_INVALID_IRMA_RESPONSE = 'invalid_irma_response',
  ERROR_NO_IVIDO_IDENTIFIER = 'no_ivido_identifier',
  ERROR_NO_USER = 'no_user',
  ERROR_MULTIPLE_USERS = 'multiple_users',
}

export interface IrmaState {
  status: IrmaStatus;
  response?: string;
  error?: any;
}
